import React from 'react';

import { StyleSheet, View } from 'react-native';
import { VictoryStack, VictoryChart, VictoryTheme, VictoryArea, VictoryTooltip } from '../shared/victory';


const yellow200 = "#FFF59D";
const deepOrange600 = "#F4511E";
const lime300 = "#DCE775";
const lightGreen500 = "#8BC34A";
const teal700 = "#00796B";
const cyan900 = "#006064";
const colors = [
  deepOrange600,
  yellow200,
  lime300,
  lightGreen500,
  teal700,
  cyan900
];
const strokeDasharray = "10, 5";
const strokeLinecap = "round";
const strokeLinejoin = "round";
const theme = {
stack: {
    colorScale: colors,

},
axis: {
style: {
    axis: {
    fill: "transparent",
    stroke: lightGreen500,
    strokeWidth: 2,
},
grid: {
    fill: "none",
    stroke: lightGreen500,
    strokeDasharray,
    strokeLinecap,
    strokeLinejoin,
    pointerEvents: "painted"
  },
  ticks: {
    fill: "transparent",
    size: 5,
    stroke: lightGreen500,
    strokeWidth: 1,
    strokeLinecap,
    strokeLinejoin
  },
  tickLabels: {
    fill: lightGreen500
  }
}
}}



const StackedChart = () => {

return (
    <View style={styles.container}>
    <VictoryChart
   theme={theme}
   animate={{ duration: 500 }}
 >
   <VictoryStack>
   <VictoryArea
    labelComponent={<VictoryTooltip renderInPortal={false}/>}
       data={[{x: "a", y: 2, label: "right-side-up"}, {x: "b", y: 3}, {x: "c", y: 5}]}
   />
   <VictoryArea
   labelComponent={<VictoryTooltip renderInPortal={false}/>}
       data={[{x: "a", y: 1}, {x: "b", y: 4}, {x: "c", y: 5, label: "right-side-up"}]}
   />
   <VictoryArea
   labelComponent={<VictoryTooltip renderInPortal={false}/>}
       data={[{x: "a", y: 3}, {x: "b", y: 2}, {x: "c", y: 6, label: "right-side-up"}]}
   />
   </VictoryStack>
 </VictoryChart>
   </View>
)}
const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
  
export default StackedChart;