import React , {useContext, useState} from 'react';
import { Layout, Text, Button, ButtonGroup} from '@ui-kitten/components';
import {useDoc}  from '../contexts/FirebaseContext'

import { View } from "react-native";





const Data = () => {
    const {data, loading, update} = useDoc('data', 'counter')
  
  
    const updateValue = (value) => {
      update({count: data.count + value})
    }
    if(loading){
      return null
        }
  
  
    
      return (
      <Layout style={{flex: 3,justifyContent: 'center', alignItems: 'center',  flexDirection: 'column'}}>
          <Text category='h1'>Data</Text>
          <View style={{flex:1, justifyContent: 'center',  alignItems: 'center'}}>
            <Text category='h3'> { data.count } </Text>
            <ButtonGroup size='large'>           
              <Button onPress={() => updateValue(-1)}>-</Button>
              <Button onPress={() => updateValue(1)}>+</Button>
            </ButtonGroup>
            
          </View>
         
      </Layout>
      );
  }

  
export default Data;