import React, { createContext, useState, useContext, useEffect}  from 'react';
import * as firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firestore'
import firebaseConfig from '../config/Firebase/firebaseConfig'


// Initialize Firebase

if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig)
}



export const userContext = createContext({
    user: null
  })
  

  export const useSession = () => {
    const { user } = useContext(userContext)
    return user
  }
  

  export const useAuth = () => {
    const [state, setState] = React.useState(() => { const user = firebase.auth().currentUser 
        return { initializing: !user, user, } })
    function onChange(user) {
      setState({ initializing: false, user })
    }
  
    React.useEffect(() => {
      // listen for auth state changes
      const unsubscribe = firebase.auth().onAuthStateChanged(onChange)
      // unsubscribe to the listener when unmounting
      return () => unsubscribe()
    }, [])
  
    return state
  }


export const useStatus = (uid) => {
    const [online, setOnline] = useState(false)
    const [loading, setLoading] = useState(true)
    useEffect(
        () => {
            var userStatusFirestoreRef = firebase.firestore().doc('/status/' + uid);

            // Firestore uses a different server timestamp value, so we'll 
            // create two more constants for Firestore state.
            var isOfflineForFirestore = {
                state: false,
                last_changed: firebase.firestore.FieldValue.serverTimestamp(),
            };

            var isOnlineForFirestore = {
                state: true,
                last_changed: firebase.firestore.FieldValue.serverTimestamp(),
            };

            firebase.database().ref('.info/connected').on('value', function(snapshot) {
                if (snapshot.val() == false) {
                    // Instead of simply returning, we'll also set Firestore's state
                    // to 'offline'. This ensures that our Firestore cache is aware
                    // of the switch to 'offline.'
                    userStatusFirestoreRef.set(isOfflineForFirestore);
                    setOnline(isOfflineForFirestore)
                } else {
                userStatusFirestoreRef.set(isOnlineForFirestore);
                setOnline(isOnlineForFirestore)
                setLoading(false)
                }
                

                // userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
                //     userStatusDatabaseRef.set(isOnlineForDatabase);

                //     // We'll also add Firestore set here for when we come online.
                //     userStatusFirestoreRef.set(isOnlineForFirestore);
                // });
                    
                });
                return () => {
                  setLoading(true)
                }


        }, [uid]
    )

      
    return {
        online, 
        loading, 
        setLoading
      }
}


  export const useDoc = (document, id) => {
    // initialize our default state
    const [error, setError] = React.useState(false) 
    const [loading, setLoading] = React.useState(true) 
    const [data, setData] = React.useState(null)
    // when the id attribute changes (including mount)
    // subscribe to the recipe document and update
    // our state when it changes.
    useEffect(
      () => {
        const unsubscribe = firebase.firestore().collection(document) .doc(id).onSnapshot( doc => { 
            
            setData(doc.data()) 
            setLoading(false) 
        }, err => { setError(err) } )
        // returning the unsubscribe function will ensure that
        // we unsubscribe from document changes when our id
        // changes to a different value.
        return () => unsubscribe()
      },
      [id]
    )

    const update = (updateData) => {
        firebase.firestore().collection(document).doc(id).update(updateData)
        .catch(error => {
            console.error("Error updating document: ", error);
        });

    }

    
  
    return {
      error,
      loading,
      data,
      update
    }
  }
  



  

export const Firebase = {
loginAnon: () => {
    return firebase.auth().signInAnonymously()
},
// auth
loginWithEmail: (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password)
},
signupWithEmail: (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password)
},
signOut: () => {
    return firebase.auth().signOut()
},
checkUserAuth: user => {
    return firebase.auth().onAuthStateChanged(user)
},

// firestore
createNewUser: userData => {
    return firebase
    .firestore()
    .collection('users')
    .doc(`${userData.uid}`)
    .set(userData)
}
}


const UserContextProvider = (props) => {
 const { user } = useAuth()
    return (
        <userContext.Provider value={{user, firebase: Firebase}}>
            {props.children}
        </userContext.Provider>
    )
}


export default UserContextProvider;
