import React, {useEffect} from 'react';
import { BottomNavigation, BottomNavigationTab} from '@ui-kitten/components';


import {  Snackbar } from 'react-native-paper';

import { View } from "react-native";
import { Route, Switch, useHistory} from "../../router/react-router";
import {useSession, useStatus}  from '../../contexts/FirebaseContext'


import { useSafeArea } from 'react-native-safe-area-context';

import Home from '../../components/Home'
import Settings from '../../components/Settings'
import Data from '../../components/Data'

import { MaterialCommunityIcons } from '@expo/vector-icons';

import useUpdateEffect from 'react-use/lib/useUpdateEffect'


import ChatBox from '../../components/Chat';


const BottomLinks = [
    { 
      id: 0,
      title: 'HOME',
      link: '/',
    },
    {
      id: 1,
      title: 'DATA',
      link: '/data',
    },
    {
      id:2,
      title: 'CHAT',
      link: '/chat',
    },
    {
      id:3,
      title: 'SETTINGS',
      link: '/settings',
    },
  ];




const AuthLayout = props =>{
  const user  = useSession()
  const {online, loading} = useStatus(user.uid)
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const isLoggedIn  = useSession()
  let history = useHistory()
  const insets = useSafeArea();

  useUpdateEffect(
    () => {
      if (!loading){
      setOpen(false);
      setTimeout( () => {
        setOpen(true);
        }, 1000);
      }
    },
    [online]
  )


  useEffect(() => {    
    isLoggedIn ? null : props.history.push("/");
    });


  const onMenuItemSelect = (index) => {
    setSelectedIndex(index)
    history.push(BottomLinks[index].link)
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  return (
    <View style={{ flex: 1, paddingTop:insets.top + 10}}>
      {online.state ?
      <MaterialCommunityIcons name="cloud-outline" size={32} color="#8BC34A" style={{alignSelf: 'flex-end', marginRight: 15}}/>
      :
      <MaterialCommunityIcons name="cloud-off-outline" size={32} color="#F4511E" style={{alignSelf: 'flex-end', marginRight: 15}}/>
      }
    <Switch>
      <Route exact path="/" render={() => {
        setSelectedIndex(0)
         return (<Home />)}
       } />
      <Route exact path="/data" render={() => {
        setSelectedIndex(1)
         return (<Data />)}
       } />
      <Route exact path="/chat" render={() => {
        setSelectedIndex(2)
         return (<ChatBox />)}
       } />
      <Route exact path="/settings" render={() => {
        setSelectedIndex(3)
         return (<Settings />)}
       } />
    </Switch>


    <View style={{ flex:1, justifyContent: 'flex-end', }}> 
    <BottomNavigation
      selectedIndex={selectedIndex}
      style={{ paddingBottom: insets.bottom + 10, marginTop: 0, paddingTop: 10}}
      onSelect={index => onMenuItemSelect(index)}>
        {BottomLinks.map((link) => (
        <BottomNavigationTab icon={link.icon} key={link.id} index={link.id} title={link.title}/>
        ))}
        
    </BottomNavigation>
    </View>
    <View >
    <Snackbar
          style={{marginBottom:70, maxWidth: 800, paddingHorizontal:10,  alignSelf: 'center',}}
          visible={open}
          onDismiss={handleClose}
          action={{
            label: 'Dismiss',
            onPress: () => {
              handleClose
            },
          }}
        >
      {online.state ?
      'You are back online'
    :
      'You are offline'
      }
      </Snackbar>
      </View>
    </View>
  );
};


export default AuthLayout;