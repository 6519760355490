import React, { useContext } from "react";
import { useSession}  from '../contexts/FirebaseContext'


import { Layout, Text, Button } from "@ui-kitten/components";




const DashboardScreen = () => {
  const isLoggedIn = useSession()
    return (
        <Layout style={{height:'100%'}}>
            < Text> 
            DASHBOARD
            LOGIN STATUS {isLoggedIn.toString()}
            </Text>

        </Layout>
      )

}

export default DashboardScreen;