import React , {useContext, useState} from 'react';
import { Layout, Text, Spinner, Button} from '@ui-kitten/components';
import {userContext}  from '../contexts/FirebaseContext'

import Constants from 'expo-constants';
import { View } from "react-native";
import { Linking } from 'expo'






const Settings = () => {
    const {firebase} = useContext(userContext)
    const [isSubmitting, setIsSubmitting] = useState(false);
  
    const handleLogout = async ()  => {
      try {
        await firebase.signOut()
  
      } catch (error) {
        console.error(error)
      }
    }
  
      return (
          <Layout style={{flex: 6,justifyContent: 'center', alignItems: 'center',  flexDirection: 'column'}}>
          <Text category='h1'>Settings</Text>
          <View style={{flex:1, justifyContent: 'flex-end',  alignItems: 'center'}}>

            <Text>Version:  {Constants.manifest.version}</Text>
            {isSubmitting ? (
                <Spinner />
              ) : (
                <Button
                  onPress={
                    () => {
                    setIsSubmitting(true)
                    setTimeout(() => {
                      handleLogout()
                      setIsSubmitting(false)
                    }, 1000)
                  }
                  }>
                  LOGOUT
                </Button>
              )}
              <Text>{Linking.makeUrl()}</Text>
          </View>
          </Layout>
      );
  }

export default Settings;