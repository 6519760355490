import React, { useEffect } from "react";
import * as eva from '@eva-design/eva';
import { ApplicationProvider, Layout, IconRegistry, Text } from '@ui-kitten/components';
import { EvaIconsPack } from '@ui-kitten/eva-icons';

import * as Analytics from 'expo-firebase-analytics'; 
import { SafeAreaProvider } from 'react-native-safe-area-context';

import UserContextProvider, {useAuth, Firebase}  from './contexts/FirebaseContext'

import DefaultRouter from './router/DefaultRouter'
import { AppLoading, SplashScreen } from 'expo';







const App = () => {

  const { initializing, user } = useAuth()
  useEffect(() => {    
    Analytics.setUserId('saitama');
    Analytics.setUserProperties({
      hero_class: 'B',
    });
    Analytics.logEvent('share', {
      contentType: 'text', 
      itemId: 'Expo rocks!', 
      method: 'facebook'
    });
  
   });


   

   if (initializing) {
    return (
      <AppLoading
       />
    )
  }


    return (
      <SafeAreaProvider>
        <IconRegistry icons={EvaIconsPack} />
          <ApplicationProvider {...eva} theme={eva.dark}>

            <UserContextProvider value={{ user, firebase: Firebase }}>
              <DefaultRouter />
            </UserContextProvider>

        </ApplicationProvider>
      </SafeAreaProvider>
    )
}


export default App;