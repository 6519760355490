import React from "react";
import { useSession}  from '../contexts/FirebaseContext'

import { Router, Route, Switch, Redirect} from "./react-router";

import PublicLayout from "../shared/layout/PublicLayout";
import AuthLayout from "../shared/layout/AuthLayout";

import LoginScreen from "../screens/LoginScreen";
import DashboardScreen from "../screens/DashboardScreen";

import { Layout } from '@ui-kitten/components';


const DefaultRouter = () => {
  const isLoggedIn = useSession()

    return (
        <Layout style={{flex:1}}>
        <Router>
        <Switch>
          <Route
                exact
                path="/"
                render={props => (
                  isLoggedIn ? 
                  <AuthLayout history={props.history} >
                    <DashboardScreen {...props} />
                  </AuthLayout>

                             :
                  <PublicLayout history={props.history} >
                    <LoginScreen {...props} />
                  </PublicLayout>
                )}
              />

          <Route
                exact
                path="/data"
                render={props => (
                  <AuthLayout history={props.history} >
                    <DashboardScreen {...props} />
                  </AuthLayout>

                )}
              />
          <Route
                exact
                path="/chat"
                render={props => (
                  <AuthLayout history={props.history} >
                    <DashboardScreen {...props} />
                  </AuthLayout>

                )}
              />
          <Route
                exact
                path="/settings"
                render={props => (
                  <AuthLayout history={props.history} >
                    <DashboardScreen {...props} />
                  </AuthLayout>

                )}
              />

        <Redirect to="/" />
        {/* Or Uncomment below to use a custom 404 page */}
        {/* <Route component={NotFoundPage} /> */}
      </Switch>
    </Router>
    </Layout>
      )

}


export default DefaultRouter;