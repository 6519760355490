import React, {useContext} from "react";
import {userContext, useSession}  from '../contexts/FirebaseContext'
import { View } from "react-native";
import { Formik } from 'formik';
import * as Yup from 'yup';


import { Layout, Text, Button, Input, Spinner } from "@ui-kitten/components";



const LoginScreen = (props) => {


  const {firebase} = useContext(userContext)





  const handleOnSignup = async (values)  => {
    const { name } = values

    try {
      const response = await firebase.loginAnon()

      if (response.user.uid) {
        const { uid } = response.user
        const userData = { name, uid }
        await firebase.createNewUser(userData)

      }
    } catch (error) {
      console.error(error)
    }
  }


    return (
      <Layout style={{flex: 3,justifyContent: 'center', alignItems: 'center',  flexDirection: 'column'}}>

            <View style={{flex:1, justifyContent: 'center',  alignItems: 'center'}}>
            <Text category='h1' >Mitch's App</Text>
              <Formik
                initialValues={{ name: '' }}
                validationSchema={
                  Yup.object().shape({
                  name: Yup
                    .string()
                    .required('Required')
                })}
                onSubmit={(values, actions) => {
                  handleOnSignup(values)
                  setTimeout(() => {
                    actions.setSubmitting(false);
                    
                  }, 1000);
                }}
              >
                {({ values, handleChange, errors, isSubmitting, handleSubmit }) => (
                  <React.Fragment>
                    <Input
                      style={{margin: 25}}
                      onChangeText={handleChange('name')}
                      placeholder="Name"
                      onSubmitEditing={handleSubmit}
                    />
                    {errors.name &&
                      <Text style={{ color: 'red' }}>{errors.name}</Text>
                    }  
                    {isSubmitting ? (
                      <Spinner />
                    ) : (
                      <Button type="submit" onPress={handleSubmit} >
                        Login
                      </Button>
                    )}
                  </React.Fragment>
                )}
              </Formik>
            </View>
           
        </Layout>

      )

}

export default LoginScreen;