import React from 'react';
import { Layout, Text} from '@ui-kitten/components';


import {useSession, useDoc}  from '../contexts/FirebaseContext'
import StackedChart from './StackedChart'






const Home = () => {

    const user  = useSession()
    const {data, loading} = useDoc('users', user.uid)


    if(loading){
      return null
        }
    
      return (
      <Layout style={{justifyContent: 'center', alignItems: 'center'}}>
          <Text category='h1' >Welcome</Text>
          {data.name ? <Text> {data.name} </Text> : null
          
          }
         <StackedChart />
         
      </Layout>
      );
  }



export default Home;