import React from "react";
import { View } from "react-native";
import { useSafeArea } from 'react-native-safe-area-context';


const PublicLayout = props => {
    const insets = useSafeArea();
  return (
    <View style={{ flex:1, paddingTop: insets.top + 10 }}>
          {props.children}
    </View>
  );
};

export default PublicLayout;