import React, {useState, useEffect, useContext} from 'react'
import { GiftedChat, Composer } from 'react-native-gifted-chat'
import { View, ActivityIndicator } from "react-native";

import {useSession, useDoc}  from '../contexts/FirebaseContext'
import * as firebase from 'firebase'

import 'firebase/auth'
import 'firebase/firestore'


const ChatBox = () => {
  const user  = useSession()
  const {data, loading} = useDoc('users', user.uid)


    const [composeMessage, setComposeMessage] = useState()
    const [chatMessages, setMessages] = useState([])


      async function onSend(newMessages) {


        const text = newMessages[0].text;

        firebase.firestore()
          .collection('chat')
          .doc(user.uid)
          .collection('messages')
          .add({
            text,
            createdAt: new Date().getTime(),
            user: {
              _id: 1,
              name: data.name,
              avatar: 'https://www.b7web.com.br/avatar2.png',
            }
          });

        
      }


  useEffect(() => {
    const messagesListener = firebase.firestore()
      .collection('chat')
      .doc(user.uid)
      .collection('messages')
      .orderBy('createdAt', 'desc')
      .onSnapshot(querySnapshot => {
        const messages = querySnapshot.docs.map(doc => {
          const firebaseData = doc.data();

          const data = {
            ...firebaseData
          };
          return data;
        });

        setMessages(messages);
      });

    return () => messagesListener();
  }, []);



      
    const renderInputToolbar = (props) => {
        return (
          <Composer {...props}
            textInputProps={{
                multiline: true,
                autoFocus: false,
                blurOnSubmit: false,
                onKeyPress: event => {
                    if (event.key === 'Enter' && event.shiftKey) {
                        null
                    } else if(event.key === 'Enter'){
                        props.onSend( {text: composeMessage} , true)
                    }
                },
          }}
          />
        );
      }


      function renderLoading() {
        return (
          <View>
            <ActivityIndicator size='large' color='#6646ee' />
          </View>
        );
      }




      return(

             <View style={{flex:6, justifyContent: 'flex-end', maxWidth:500, marginHorizontal:15}}>
             <GiftedChat
                messages={chatMessages}
                alwaysShowSend={true}
                showUserAvatar={true}
                quickReplyStyle={{backgroundColor:'white'}}
                onSend={messages => onSend(messages)}
                onInputTextChanged={message => setComposeMessage(message)}
                user={{
                    _id: 1,
                    name: 'Monica',
                    avatar: 'https://placeimg.com/140/140/people'
                  }}
            
                renderUsernameOnMessage={true}
                renderComposer={renderInputToolbar}
                scrollToBottom
                isTyping={false}
                renderLoading={renderLoading}
            />
            </View>


      )
    }

    export default ChatBox;