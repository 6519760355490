export default {
    apiKey: "AIzaSyDqE4rnLLozDpUArlrfZm0Axv1MzT8tqxk",
    authDomain: "mitch-app.firebaseapp.com",
    databaseURL: "https://mitch-app.firebaseio.com",
    projectId: "mitch-app",
    storageBucket: "mitch-app.appspot.com",
    messagingSenderId: "658062244585",
    appId: "1:658062244585:web:edf3fc9229bd64ead7ce78",
    measurementId: "G-BSNN1Y0J07"
    }
  